
import { MeasuringRunStatus, MEASURING_RUN_STATUSES_DISPLAY } from "@/domain";
import { Component, Vue, Prop } from "vue-property-decorator";
import _ from "lodash";

@Component
export default class StatusElement extends Vue {
  @Prop({ required: true })
  value!: MeasuringRunStatus;

  @Prop({ required: false, default: false })
  iconMode!: boolean;

  getStatusDisplay(status: MeasuringRunStatus) {
    return MEASURING_RUN_STATUSES_DISPLAY.get(status);
  }
}
