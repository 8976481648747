
import { Component, Inject, Vue } from "vue-property-decorator";
import { DependencyContainer } from "tsyringe";

import { SERVICES as S, PROVIDER } from "@/config/literals";
import { IMockExcelExamReportService } from "@/services";

@Component
export default class DebugImportExamReport extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  get mockExcelImportExamReportService() {
    return this.container.resolve<IMockExcelExamReportService>(
      S.MOCK_EXCEL_EXAM_REPORT_SERVICE
    );
  }

  loading: boolean = false;
  operationalFileNumber: string | null = null;
  examReportNumber: string | null = null;
  powerPlantName: string | null = null;
  nuclearUnitNumber: number | null = null;
  systemTrigram: string | null = null;
  equipmentNgram: string | null = null;
  equipmentNumber: string | null = null;
  zoneTag: string | null = null;
  intradosNominal: number | null = null;
  intradosMinManufactured: number | null = null;
  intradosCalculation: number | null = null;
  intradosCharacterisation: number | null = null;
  extradosNominal: number | null = null;
  extradosMinManufactured: number | null = null;
  extradosCalculation: number | null = null;
  extradosCharacterisation: number | null = null;
  proceadureName: string | null = null;
  uncertaintyProceadureName: string | null = null;

  async mockImport() {
    try {
      this.loading = true;
      await this.mockExcelImportExamReportService.mockImport({
        operationalFileNumber:
          this.operationalFileNumber != null ? this.operationalFileNumber : "",
        examReportNumber:
          this.examReportNumber != null ? this.examReportNumber : "",
        powerPlantName: this.powerPlantName != null ? this.powerPlantName : "",
        nuclearUnitNumber:
          this.nuclearUnitNumber != null ? this.nuclearUnitNumber : 1,
        hydraulicSystemTrigram:
          this.systemTrigram != null ? this.systemTrigram : "",
        equipmentNgram: this.equipmentNgram ? this.equipmentNgram : "",
        equipmentNumber: this.equipmentNumber ? this.equipmentNumber : "",
        zoneTag: this.zoneTag != null ? this.zoneTag : "",
        proceadureName: this.proceadureName,
        uncertaintyProceadureName: this.uncertaintyProceadureName,
        intradosNominal: this.intradosNominal,
        intradosMinManufactured: this.intradosMinManufactured,
        intradosCalculation: this.intradosCalculation,
        intradosCharacterisation: this.intradosCharacterisation,
        extradosNominal: this.extradosNominal,
        extradosMinManufactured: this.extradosMinManufactured,
        extradosCalculation: this.extradosCalculation,
        extradosCharacterisation: this.extradosCharacterisation,
      });
    } finally {
      this.loading = false;
    }
  }
}
