
import { Component, Vue, Prop, Inject } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";
import { PROVIDER, SERVICES as S } from "@/config/literals";

import { IMeasuringRunService } from "@/services";

import {
  ExamReportInfos,
  MeasuringRunResume,
  MeasuringRunStatus,
  OperationalFileInfos,
} from "@/domain";

@Component
export default class RunAutocomplete extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  value!: MeasuringRunResume | null;

  @Prop({ required: false, default: null })
  runId!: string | null;

  @Prop({ required: false })
  filterStatus!: MeasuringRunStatus;

  get measuringRunService() {
    return this.container.resolve<IMeasuringRunService>(
      S.MEASURING_RUN_SERVICE
    );
  }

  get filteredRuns() {
    return _.filter(this.runsResume, (s) => s.status == this.filterStatus);
  }

  runsResume: MeasuringRunResume[] = [];
  loading: boolean = true;

  mounted() {
    this.fetch();
  }

  async fetch() {
    try {
      this.loading = true;
      this.runsResume = await this.measuringRunService.getResume();
      if (this.runId != null) {
        this.$emit(
          "input",
          _.find(this.runsResume, (r) => r.id == this.runId)
        );
      }
    } finally {
      this.loading = false;
    }
  }

  filter(
    item: {
      id: string | null;
      examReport: ExamReportInfos;
      operationalFile: OperationalFileInfos;
    },
    queryText: string,
    itemText: string
  ) {
    const textOne = item.examReport.number.toLowerCase();
    const textTwo = item.operationalFile.number.toLowerCase();
    const searchText = queryText.toLowerCase();

    return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
  }
}
