
import { Component, Vue } from "vue-property-decorator";

import DebugImportExamReport from "@/components/SuperAdmin/Debug/DebugImportExamReport.vue";
import DebugExportExamReport from "@/components/SuperAdmin/Debug/DebugExportExamReport.vue";
import DebugRunStatus from "@/components/SuperAdmin/Debug/DebugRunStatus.vue";

@Component({
  components: {
    DebugImportExamReport,
    DebugExportExamReport,
    DebugRunStatus,
  },
})
export default class Debug extends Vue {}
