
import { Component, Vue } from "vue-property-decorator";

import RunAutocomplete from "@/components/General/Autocompletes/ExamReports/RunAutocomplete.vue";

import { MOCK_EXPORT_EXCEL_EXAM_REPORT_URL } from "@/config/urls";

import { MeasuringRunResume, MeasuringRunStatus } from "@/domain";

@Component({
  components: { RunAutocomplete },
  data: () => {
    return {
      MeasuringRunStatus,
    };
  },
})
export default class DebugExportExamReport extends Vue {
  get exportUrl(): string {
    return this.runResume != null
      ? MOCK_EXPORT_EXCEL_EXAM_REPORT_URL(this.runResume.id)
      : "";
  }

  runResume: MeasuringRunResume | null = null;
}
