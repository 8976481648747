
import { Component, Prop, Vue } from "vue-property-decorator";
import { lightFormat } from "date-fns";

import { EMPTY } from "@/config/literals";

import { MeasuringRunResume, MeasuringRunStatus } from "@/domain";
import { UserInfos } from "prometheus-synced-ui";

import StatusElement from "@/components/Core/ExamReports/Status/StatusElement.vue";
import MeasuringRunActions from "@/components/Core/ExamReports/Dashboard/MeasuringRunActions.vue";

@Component({
  components: {
    StatusElement,
    MeasuringRunActions,
  },
  data: () => {
    return {
      MeasuringRunStatus,
    };
  },
  filters: {
    pretty(date: Date): string {
      return lightFormat(date, "dd/MM/yyyy");
    },
    prettyEmpty(controller: UserInfos | null): string {
      return controller != null ? controller.fullName : EMPTY;
    },
  },
})
export default class DebugMeasuringRunsTable extends Vue {
  @Prop({ required: true })
  runsResume!: MeasuringRunResume[];

  @Prop({ required: true })
  loading!: boolean;

  get headers() {
    return [
      {
        text: "N° DO",
        align: "center",
        sortable: true,
        value: "operationalFile.number",
      },
      {
        text: "N° RE",
        align: "center",
        sortable: true,
        value: "examReport.number",
      },
      {
        text: "N° Session",
        align: "center",
        sortable: true,
        value: "version",
      },
      {
        text: "Site / Tranche",
        align: "center",
        sortable: true,
        value: "plantUnit",
      },
      {
        text: "Système",
        align: "center",
        sortable: true,
        value: "system.trigram",
      },
      {
        text: "Équipement",
        align: "center",
        sortable: true,
        value: "equipment",
      },
      {
        text: "Repère Zone",
        align: "center",
        sortable: true,
        value: "zone.tag",
      },
      {
        text: "Création RE",
        align: "center",
        sortable: true,
        value: "creation",
      },
      {
        text: "Contrôleur",
        align: "center",
        sortable: true,
        value: "controller",
      },
      {
        text: "Statut",
        align: "center",
        sortable: true,
        value: "status",
      },
      {
        text: "Actions",
        align: "end",
        sortable: false,
        value: "actions",
      },
    ];
  }

  search: string | null = null;
  removedId: string | null = null;

  remove(id: string) {
    this.removedId = id;
    this.$emit("remove", id);
  }
}
