
import { Component, Inject, Vue } from "vue-property-decorator";
import { DependencyContainer } from "tsyringe";
import _ from "lodash";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import { MeasuringRunResume } from "@/domain";

import { IMeasuringRunService } from "@/services";

import Loading from "@/views/Loading.vue";
import DebugMeasuringRunsTable from "@/components/SuperAdmin/Debug/DebugMeasuringRunsTable.vue";

@Component({
  components: {
    Loading,
    DebugMeasuringRunsTable,
  },
})
export default class DebugRunStatus extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  get measuringRunService() {
    return this.container.resolve<IMeasuringRunService>(
      S.MEASURING_RUN_SERVICE
    );
  }

  fetching: boolean = true;
  removing: boolean = false;
  runsResume: MeasuringRunResume[] = [];

  mounted() {
    this.fetch();
  }

  async fetch() {
    try {
      this.fetching = true;
      this.runsResume = await this.measuringRunService.getResume();
    } finally {
      this.fetching = false;
    }
  }

  async remove(runId: string) {
    try {
      this.removing = true;
      await this.measuringRunService.superAdminRemove(runId);
      const index = _.findIndex(this.runsResume, (r) => r.id == runId);
      if (index != -1) {
        this.runsResume.splice(index, 1);
      }
    } finally {
      this.removing = false;
    }
  }
}
