
import { Component, InjectReactive, Prop, Vue } from "vue-property-decorator";

import { SERVER_CONFIG } from "@/config/literals";
import { EXPORT_EXCEL_EXAM_REPORTS_URL } from "@/config/urls";

import { LITE_FEATURE, MeasuringRunResume, MeasuringRunStatus } from "@/domain";
import { ServerConfig } from "prometheus-synced-ui";

import { Feature } from "vue-feature-flags";

@Component({
  components: {
    Feature,
  },
  data: () => {
    return {
      MeasuringRunStatus,
      LITE_FEATURE,
    };
  },
})
export default class MeasuringRunActions extends Vue {
  @InjectReactive(SERVER_CONFIG)
  serverConfig!: ServerConfig;

  @Prop({ required: true })
  item!: MeasuringRunResume;

  @Prop({ required: true })
  loading!: boolean;

  removedId: string | null = null;
  canceledId: string | null = null;

  editUrl(id: string) {
    return {
      name: "exam-report-edit",
      params: {
        runId: id,
      },
    };
  }

  remove(id: string) {
    this.removedId = id;
    this.$emit("remove", id);
  }

  cancel(id: string) {
    this.canceledId = id;
    this.$emit("cancel", id);
  }

  exportUrl(id: string) {
    return EXPORT_EXCEL_EXAM_REPORTS_URL(id);
  }
}
